import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import BottomContact from '../../components/BottomContact';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import AboutCircle from '../../components/Circles/aboutCircle';
import Meta from '../../components/Meta';
import NavAbout from '../../components/Navbar/navAbout';

import './index.scss';

class About extends React.Component {
  render() {
    return (
      <Page classnames="terms-page">
        <Meta title="Terms and Conditions" type={"website"} />
        <PageHeader>
          <h1>Terms &amp; Conditions</h1>
          <img
            src="/images/circles/topRightBlue.svg"
            height={500}
            style={{ position: 'absolute', top: 0, right: 0, zIndex: -1 }}
          />
        </PageHeader>

        <AboutCircle>
          <div className="container">
            <div className="row pb-spacer-30 justify-content-center align-items-center">
              <div className="col-12 col-md-8 spacer-30">
                <h4 className="text-left mt-5">1. Definitions</h4>
                <p>For the purposes of these terms:</p>
                <p>
                  <strong>We</strong> and <strong>Us</strong> means Brighton
                  School of Business and Management Ltd who will provide the
                  service to you.
                </p>
                <p>
                  <strong>You</strong> means the student receiving the services.
                </p>
                <p>
                  <strong>Course</strong> means an online distance learning
                  course.
                </p>
                <p>
                  <strong>Course Materials</strong> means the materials relating
                  to any one course, together with all revision and updates made
                  by us, which may be in any format and which are required by
                  the student to complete the course.
                </p>
                <h4 className="text-left mt-5">2. Cancellation of Course</h4>
                <p>
                  Your statutory right under the Consumer Contracts Regulations
                  June 2014, which implement the Consumer Rights Directive in UK
                  law, allows 14 days from entering into a service contract in
                  which you can cancel it. This means that you have 14 days from
                  your enrolment on a course to request cancellation. To avoid
                  any misunderstandings, emails or telephone cancellations are
                  not acceptable and you must send your cancellation request in
                  writing to Student Services. After the 14 days have elapsed we
                  cannot accept any course cancellation under any circumstances
                  and all the fees are payable. No refund of deposit or stage
                  payments will be made if the student withdraws later than
                  this. Outstanding fees must be paid in full.
                </p>
                <p>
                  If you withdraw from a course, you will be charged a
                  Withdrawal Administration fee of GBP £250.
                </p>
                <p>Registration fees are non-refundable.</p>
                <p>
                  If, while already studying a course with Brighton School of
                  Business and Management, you wish to transfer to another
                  course which is of a lesser value, no refund of the
                  difference, if any, will be made. Brighton School of Business
                  and Management will only consider a refund of any difference
                  if the request to change course is made within 14 days of
                  enrolment.
                </p>
                <h4 className="text-left mt-5">3. Fee Payments</h4>
                <p className="mt-2">
                  Study Fees must be paid according to the payments schedule
                  agreed when you enrol with us. Please note that this also
                  applies during informal or formally arranged interruptions to
                  your studies.
                </p>
                <p>
                  If a student has chosen to pay their fees by instalments, we
                  will require debit / credit card details for payments. The
                  credit / debit card will be charged on 1st of each month with
                  the scheduled payment instalment, for the duration of the
                  issued payment plan. All information is held confidently and
                  securely, and is destroyed after the student has finished
                  their payments.
                </p>
                <p>
                  We can ONLY accept debit / credit card payments for instalment
                  payments. We do not accept any other forms of payment for
                  instalment payments. A payment form MUST be completed and
                  returned to the Accounts Department before you are enrolled on
                  the course.
                </p>
                <p>
                  When payment has been agreed by way of an instalment plan, you
                  agree to make the required payment on the dates agreed from
                  the outset. In the event of a payment not being received, you
                  agree to pay the sum due within 24 hours of being notified or
                  an administration fee will be applied.
                </p>
                <p>
                  Interest will be charged on late payments under the Late
                  Payment of Commercial Debts (Interest) Act 1998.
                </p>
                <p>
                  <b>
                    Should your payment not be received within 7days from the
                    due date, then you will be in breach of contract and the
                    full amount owing for the course will become due for
                    immediate payment.
                  </b>
                </p>
                <p>
                  If necessary, a Debt Recovery Agency will be appointed to
                  recover outstanding payments. The Debt Recovery Agency will
                  apply further costs for the collection of the amount owing.
                  Once your account has been passed to a Debt Recovery Agency,
                  you will deal exclusively with the Agency. Once you have been
                  passed to a Debt Recovery Agency, you will not be able to
                  re-enrol on any of our courses.
                </p>
                <p>
                  Students who withdraw after they have officially started the
                  course, or are formally withdrawn from the course by the
                  school, will be required to pay all outstanding fees for the
                  course. To avoid any doubt, you have entered into a legally
                  binding contract to pay the course fees in full and any
                  additional fees where appropriate. No refund will be given if
                  a student fails a course.
                </p>
                <h4 className="text-left mt-5">
                  4. Postal and Courier Charges
                </h4>
                <p>
                  Initial Postal and Courier charges are included in the study
                  fee, and paid for by us when sending study materials and
                  certificates. However, please note that if the study materials
                  or certificate are returned to us, due to you providing an
                  incorrect or inadequate address, or the study materials or
                  certificates are not being picked up from a notified
                  collection point, or you changing address during the delivery
                  period, you will be charged for the cost of re-sending the
                  materials and or certificate.
                </p>
                <h4 className="text-left mt-5">5. Interruption of Studies</h4>
                <p>
                  During the course, you may experience personal difficulties
                  and can temporarily stop or delay your studies, for a period
                  of up to 3 months, agreed with Student Services. An
                  Interruption of Studies form must be completed to formalise
                  this arrangement. Please note that retrospective approval for
                  a formal interruption of studies will not be allowed.
                </p>
                <p>
                  If you fail to restart your studies after a period of 2 months
                  from your restart date you will be deemed to have left the
                  course.
                </p>
                <p>
                  A maximum of 3 months interruption of studies can be taken
                  during the period of the course.
                </p>
                <p>
                  You can only apply for an interruption of studies after 3
                  months from your official course start date.
                </p>
                <p>
                  Interruption of studies applies only to Diploma level courses.
                  {/* and HND/HNC courses. */}
                </p>
                <p>
                  During any informal or formally arranged Interruption of
                  Studies, stage payments must continue to be paid, as agreed at
                  the start of the course.
                </p>
                <h4 className="text-left mt-5">
                  6. Assignment Submission Deadlines
                </h4>
                <p>
                  All students must submit their assignments according to the
                  deadlines on the timetable, emailed at the start of the
                  course. A student can apply for extensions on their assignment
                  submission deadlines, but they can only apply for two
                  extensions for the duration of their course. Students can
                  apply for up to 3 weeks extensions on two assignments for the
                  duration of their course. Students, who are studying courses
                  that consist of 1 unit, can ONLY have one extension on their
                  assignment. When students are granted an extension, this does
                  not affect future assignment deadlines.
                </p>
                <p>Extensions must be given officially by Student Services.</p>
                <p>It is the students responsibility to apply for an extension or interruption. Brighton School of Business and Management reserve the right to deny access, without notice, to the course, for any student if a submission deadline is not met and no contact has been made by the student.</p>
                <h4 className="text-left mt-5">
                  7. Quality Assurance of Completed Work
                </h4>
                <p>
                  To meet the Accreditation body’s Quality Assurance guidelines,
                  Brighton School of Business and Management, Pearson Edexcel,
                  Chartered Management Institute (CMI) and the Chartered Quality
                  Institute (CQI) can select any assignment and assessment to be
                  audited at any time throughout a course up until certification
                  has been completed. This may be for internal or external
                  verification and Brighton School of Business and Management
                  and the Accreditation bodies have the right to disagree with
                  any grading decisions already received. Any decisions that are
                  disagreed will be communicated to the student and
                  tutor/assessor with the student given an opportunity to
                  resubmit the assignment. For avoidance of doubt, an assignment
                  and assessment can be selected at any time, regardless of when
                  it was completed.
                </p>
                <h4 className="text-left mt-5">8. Contact</h4>
                <p>
                  Should you not submit an assignment to us for a period of
                  longer than 2 months, you will be deemed to have withdrawn
                  from the course, and will not be eligible for a refund of fees
                  paid. Any outstanding fees must be paid. The Journal is NOT an
                  assignment.
                </p>
                <p>
                  If your course does not require assignments, then you must
                  stay in contact with your tutor. It you do not contact your
                  tutor for a period of longer than 2 months, then you will be
                  deemed to have withdrawn from the course, and will not be
                  eligible for a refund of fees paid. Any outstanding fees must
                  be paid.
                </p>
                <p>
                  Please note that retrospective approval for a formal
                  interruption of studies will not be allowed.
                </p>
                <p>
                  It may be possible, in the case of exceptional circumstances,
                  supported by the provision of satisfactory evidence, to allow
                  you to restart the course. You will be required to pay a
                  re-start fee of £250.
                </p>
                <p>
                  We cannot re-start you on your course if you have been out of
                  contact for 4 months or more.
                </p>
                <p>
                  The final decision about re-starting students will be entirely
                  at the discretion of the School’s Senior Management Team.
                </p>
                <h4 className="text-left mt-5">9. Plagiarism</h4>
                <p>
                  If you are found guilty of plagiarism (submitting coursework
                  which is not your own work) you will be removed from the
                  course. Fees already paid will not be refunded, and any
                  outstanding fees will remain due for payment.
                </p>
                <h4 className="text-left mt-5">10. Other Terms</h4>
                <p>Please note that we can only communicate with the student directly enrolled with Brighton School of School and Management, we cannot communicate with any third party.</p>
                <p>All coursework and questions must be uploaded to your tutor via the upload facilities provided by Moodle.</p>
                <p>It is the students responsibility as a private candidate to find an examination centre, if the course requires examinations.</p>
                <p>As a provider, and in line with updated accreditation criteria, Brighton School of Business and Management can only allow one submission per unit for grading. If after this your assignment has still not achieved at least a Pass, Brighton School of Business and Management, at its discretion, may allow one final attempt. If this final attempt has still not achieved at least a Pass, the assignment and course will be a fail.</p>
                <p>If the student requests a further attempt, this will be subject to agreement by the Centre Management team and will carry a unit fee of £200 to retake the unit.</p>
                <p>If you are studying a CMI course, then specifically CMI will allow Learners to submit the original assignment and grant two further submissions should they receive a refer grade (in other words, a total of 3 submissions is permitted). If the Learner is required to resubmit an assignment, they should only amend assessment criteria that have been referred.</p>
                <h4 className="text-left mt-5">11. Complaints</h4>
                <p>
                  If you have a complaint, concern or criticism about any
                  services we offer, please contact us at
                  studentservices@brightonsbm.com.
                </p>
                <h4 className="text-left mt-5">12. Data Protection</h4>
                <p>
                  This privacy statement applies to any personal data you may
                  give to Brighton School of Business and Management Ltd. We
                  collect any personal data you may give us when you begin your
                  course. We will also collect contact details from prospective
                  students. We maintain data on your transactions with us as
                  well as your use of our services.
                </p>
                <p>The personal information which we hold is held securely.</p>
                <h4 className="text-left mt-5">13. Transferring the Course</h4>
                <p>We are unable to transfer a course to a third party.</p>
                <p>
                  We retain the sole discretion concerning whether we will allow
                  you to transfer to another course. If we allow you to transfer
                  to another course, the total fees paid towards the discontinued
                  course may be offset against the cost of the new course; no
                  refund of any differences will be made. If the new course is
                  more expensive than the discontinued course, the balance
                  should be paid in full. Fees will ONLY be offset for the
                  following courses – 
                  {/* HND to HNC, */}
                  Level 5 Management and
                  Leadership and Level 7 Management and Leadership courses.
                  There will be a transfer fee of £200. You may also be charged
                  a re-registration fee for the awarding body of that course.
                </p>
                <h4 className="text-left mt-5">14. Student Behaviour</h4>
                <p>
                  Brighton School of Business and Management Ltd will not accept
                  any inappropriate behaviour directed at any member of staff
                  under any circumstances. This includes bad language and
                  threats via email, in writing, over the telephone or in
                  person. Brighton School of Business and Management Ltd
                  reserves the right to remove the student from their course,
                  with no entitlement to a refund of fees and payment for any
                  outstanding fees will be required in full.
                </p>
                <h4 className="text-left mt-5">15. Moodle - Terms of Use</h4>
                <p>When using the VLE (Moodle), students must:</p>
                <ul>
                  <li>Respect the rights of others.</li>
                  <li>Remember to logout at the end of each session.</li>
                </ul>
                <p>Comply with current and future legislations including:</p>
                <ul>
                  <li>The Data Protection Act 1998</li>
                  <li>Copyright, Designs & Patents Act 1988</li>
                  <li>Computer Misuse Act 1990</li>
                  <li>Telecommunications Act 1984</li>
                  <li>Protection from Harassment Act 1997</li>
                </ul>
                <p>The following are not permitted:</p>
                <ul>
                  <li>The creation, display, production, storage, circulation or
                    transmission of pornographic or other offensive material in
                    any form or medium. (Including sending, posting or displaying
                    offensive images, language or any other type of offensive
                    content including the bullying, harassment or intimidation of
                    others.)</li>
                </ul>
                <p>Users must not create, store or transmit:</p>
                <ul>
                  <li>Defamatory or libelous material</li>
                  <li>Material that infringes copyright</li>
                  <li>Unsolicited commercial or advertising material</li>
                </ul>
                <p>Users are not permitted to:</p>
                <ul>
                  <li>Intentionally provide or submit false information</li>
                </ul>
                <p>
                  Intentionally restrict network traffic with high bandwidth
                  use.
                </p>
                <p>
                  Flood forums and other services unnecessarily or with
                  inappropriate content/material.
                </p>
                <p>
                  Edit or modify any element of the VLE without
                  permission.
                </p>
                <p>Use others’ passwords or log-in identities.</p>
                <p>
                  Deliberately introduce any virus, worm, Trojan horse or
                  other harmful or nuisance programme or file, or deliberately
                  circumvent any precautions taken by Brighton School of
                  Business and Management to prevent this from happening.
                </p>
                <p>Use any service for commercial purposes or profit.</p>
                <p>Use any service for political purposes.</p>
                <p>Use any service inappropriately during class time.</p>
                <p>
                  Copy any code, software or content provided without
                  permission.
                </p>
                <p>
                  Provide access to non-Brighton School of Business and
                  Management members without permission.
                </p>
                <p>
                  Violate copyright laws, data protection laws and computer
                  misuse laws.
                </p>
                <h4 className="text-left mt-5">
                  16. Terms of Use for Related Websites
                </h4>
                <p>
                  The use of the Brighton School of Business and Management Ltd
                  Web Site is subject to these terms and by using the site
                  www.BrightonSBM.com and any websites related to Brighton
                  School of Business and Management, including
                  www.BSBM-Moodle.com, you show your agreement to such terms.
                </p>
                <h4 className="text-left mt-5">17. Awarding Bodies</h4>
                <p>
                  Brighton School of Business and Management Ltd reserves the
                  right to change the awarding body we use for qualifications
                  and will always offer an equivalent or better qualification.
                </p>
              </div>
            </div>
          </div>
        </AboutCircle>
        <BottomContact />
      </Page>
    );
  }
}

const mapStateToProps = state => ({
  courses: state.courses.courses
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(About);
